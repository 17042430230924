import axios from "axios";
import { couponValidity, resetCoupon } from "../slices/coupon-slice";
import cogoToast from "cogo-toast";
const API_URL = process.env.REACT_APP_API_HOST;

export const couponCheck = ({ coupon, products }) => {
  return async (dispatch) => {
    try {
      await axios
        .post(`${API_URL}/coupon/validity`, {
          couponCode: coupon,
          products: products,
        })
        .then((res) => {
          dispatch(couponValidity(res.data));
          cogoToast.success("Coupon Applied");
        })
        .catch((err) => {
          dispatch(couponValidity(err.response.data));
          cogoToast.error(err.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const resetCoupons = () => {
  return async (dispatch) => {
    try {
      dispatch(resetCoupon());
    } catch (error) {
      console.log(error);
    }
  };
};
