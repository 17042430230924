import React from "react";
import { Fragment } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import cogoToast from "cogo-toast";
import { useEffect } from "react";

const ResetPassword = () => {
    const host = process.env.REACT_APP_API_HOST;
    const [email, setEmail] = useState("");
    const { token } = useParams();
    let { pathname } = useLocation();
    const [verify, setVerify] = useState(false);
    const [error, setError] = useState(false);
    const redirect = useNavigate()
    const [Message, setMessage] = useState("");
    const [password, setPassword] = useState({
        password: "",
        passwordConfirm: "",
    });

    useEffect(() => {
        if (token) {
            setVerify(true);
        }
    }, [token]);

    const handlePasswordChange = (e) => {
        setPassword({
            ...password,
            [e.target.name]: e.target.value,
        });
    };

    const handleClick = async (e) => {
        e.preventDefault();

        await axios
            .post(`${host}/reset/password`, {
                email,
            })
            .then((res) => {
                cogoToast.success(res.data.message, { position: "top-center" });
            })
            .catch((err) => {
                cogoToast.error(err.response.data.message, { position: "top-center" });
            });
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        await axios
            .post(`${host}/reset/password/confirm`, {
                password: password.password,
                token,
            })
            .then((res) => {
                cogoToast.success(res.data.message, { position: "top-center" });
                redirect("/login");
                setEmail("");
            })
            .catch((err) => {
                cogoToast.error(err.response.data.message, { position: "top-center" });
            });
    };

    useEffect(() => {
        if (token) {
            //   if the password is empty and password did not match then setError will be true
            if (password.password === "" || password.passwordConfirm === "") {
                setError(true);
                setMessage("");
            }
            //   if the password is not empty and password did not match then setError will be true
            else if (password.password !== password.passwordConfirm) {
                setError(true);
                setMessage("Password did not match");
            }
            //   if the password is not empty and password did match then setError will be false
            else if (password.password === password.passwordConfirm) {
                setError(false);
                setMessage("");
            }
            else {
                setError(false);
                setMessage("");
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password.password, password.passwordConfirm]);

    return (
        <Fragment>
            <SEO
                titleTemplate="Reset Password"
                description="Reset Password of America's Silver Collection account"
            />
            <LayoutOne
                headerTop="visible"
                headerContainerClass="container-fluid"
                headerPaddingClass="header-padding-1"
            >
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        {
                            label: "Reset Password",
                            path: process.env.PUBLIC_URL + pathname,
                        },
                    ]}
                />
                <div className="login-register-area pt-50 pb-50">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                {verify ? (
                                    <div className="card mb-3">
                                        <div className="login-register-wrapper">
                                            <div className="login-form-container">
                                                <h3 className="mb-4 text-center">Reset Password</h3>
                                                <div className="login-register-form">
                                                    <label htmlFor="" className="mb-1">
                                                        Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        className="mb-3"
                                                        placeholder="Password"
                                                        onChange={(e) => handlePasswordChange(e)}
                                                        value={password.password}
                                                    />

                                                    <label htmlFor="" className="mb-1">
                                                        Confirm Password
                                                    </label>
                                                    <input
                                                        type="password"
                                                        name="passwordConfirm"
                                                        placeholder="Confirm Password"
                                                        className="mb-3"
                                                        onChange={(e) => handlePasswordChange(e)}
                                                        value={password.passwordConfirm}
                                                    />
                                                    {Message && (
                                                        <div className="alert alert-danger" role="alert">
                                                            {Message}
                                                        </div>
                                                    )}

                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            type="submit"
                                                            className="button-box"
                                                            onClick={(e) => handleResetPassword(e)}
                                                            disabled={error}
                                                        >
                                                            <span>Reset Password</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card mb-3">
                                        <div className="login-register-wrapper">
                                            <div className="login-form-container">
                                                <h3 className="mb-4 text-center">Reset Password</h3>
                                                <div className="login-register-form">
                                                    <label htmlFor="" className="mb-1">
                                                        Enter your email address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        placeholder="Email"
                                                        className="mb-4"
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />

                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            type="submit"
                                                            className="button-box"
                                                            onClick={(e) => handleClick(e)}
                                                        >
                                                            <span>Reset Password</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    );
};

export default ResetPassword;
