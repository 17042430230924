import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    address: [],
};

const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        setAddress: (state, action) => {
            return action.payload
        },
        addNewAddress: (state, action) => {
            state.push(action.payload);
        },
        updateAddress: (state, action) => {
            const index = state.findIndex((address) => address.id === action.payload.id);
            if (index !== -1) {
                state[index] = action.payload.updatedAddress;
            }
        },
        deleteAddressById: (state, action) => {
            const idToDelete = action.payload;
            return state.filter(address => address.id !== idToDelete);
        },
    },
});

export const { setAddress, addNewAddress, updateAddress, deleteAddressById } = addressSlice.actions;
export default addressSlice.reducer;
