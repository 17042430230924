import { setCategories } from "../slices/category-slice";
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_HOST

export const fetchCategories = () => {
    return async dispatch => {
        try {
            const response = await axios.get(`${API_URL}/category`);
            dispatch(setCategories(response.data));
        } catch (error) {

        }
    };
};