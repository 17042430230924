import { setProducts } from "../slices/product-slice";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_HOST;

export const fetchProducts = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/products`);
      dispatch(setProducts(response.data));
    } catch (error) {}
  };
};

export const fetchProductTier = () => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("xat"),
        },
      };
      const response = await axios.get(
        `${API_URL}/products/tier-products`,
        config
      );
      dispatch(setProducts(response.data));
    } catch (error) {}
  };
};
