import { Suspense, lazy, useEffect } from "react";
import "./App.css";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  fetchProductTier,
} from "./store/actions/productActions";
import { getAddress } from "./store/actions/addressAction";
import { fetchCategories } from "./store/actions/categoryActions";
import { fetchSubCategories } from "./store/actions/subcategoryActions";
// import categoryData from "./data/category/category-five.json";
import "react-loading-skeleton/dist/skeleton.css";
// import Payment from "./pages/other/Payment";
import ExistingCustomer from "./pages/other/ExistingCustomer";
import ResetPassword from "./pages/other/ResetPassword";
import { resetCoupons } from "./store/actions/couponActions";

// home pages
const HomeFurnitureFour = lazy(() => import("./pages/home/HomeFurnitureFour"));

const ReturnPolicy = lazy(() => import("./pages/other/ReturnPolicy"));
const PrivacyPolicy = lazy(() => import("./pages/other/PrivacyPolicy"));
const ShippingPolicy = lazy(() => import("./pages/other/ShippingPolicy"));

// other pages
const Payment = lazy(() => import("./pages/other/Payment"));

// shop pages
const Shop = lazy(() => import("./pages/shop/Shop"));

// product pages
const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const Login = lazy(() => import("./pages/other/Login"));
const Register = lazy(() => import("./pages/other/Register"));
const SizeGuide = lazy(() => import("./pages/other/SizeGuide"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const ThankYou = lazy(() => import("./pages/other/ThankYou"));
const StoreLocator = lazy(() => import("./pages/other/StoreLocator"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const MyOrders = lazy(() => import("./pages/other/MyOrders"));

const App = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);
  const { productsInCoupon } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSubCategories());
    dispatch(fetchCategories());
    if (isAuthenticated) {
      dispatch(fetchProductTier());
      dispatch(getAddress());
    } else {
      dispatch(fetchProducts());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (cartItems.length === 0) {
      dispatch(resetCoupons());
    } else {
      const filterProducts = cartItems?.filter((item) => {
        return productsInCoupon?.includes(item._id);
      });
      if (filterProducts?.length === 0) {
        dispatch(resetCoupons());
      }
    }

    // eslint-disable-next-line
  }, [cartItems]);

  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + `/category/:category`}
              element={<Shop />}
            />

            <Route
              path={process.env.PUBLIC_URL + `/category/:category/:subcategory`}
              element={<Shop />}
            />

            <Route
              path={process.env.PUBLIC_URL + `/shop/metal-type/:metalType/`}
              element={<Shop />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<HomeFurnitureFour />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/reset-password"}
              element={<ResetPassword />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reset-password/:token"}
              element={<ResetPassword />}
            />

            {/* Shop pages */}
            <Route
              path={process.env.PUBLIC_URL + "/shop"}
              element={<Shop category="all" />}
            />

            {/* Shop product pages */}
            <Route
              path={process.env.PUBLIC_URL + "/product/:id"}
              element={<ProductTabLeft />}
            />

            {/* Blog pages */}
            <Route
              path={process.env.PUBLIC_URL + "/blog-standard"}
              element={<BlogStandard />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
              element={<BlogNoSidebar />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
              element={<BlogRightSidebar />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/blog-details-standard"}
              element={<BlogDetailsStandard />}
            />

            {/* Other pages */}
            <Route
              path={process.env.PUBLIC_URL + "/about"}
              element={<About />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              element={<Contact />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/login"}
              element={<Login />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/store-location"}
              element={<StoreLocator />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/register"}
              element={<Register />}
            />

            <Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />
            <Route
              path={process.env.PUBLIC_URL + "/wishlist"}
              element={<Wishlist />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/compare"}
              element={<Compare />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              element={<Checkout />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/payment"}
              element={<Payment />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/thankyou"}
              element={<ThankYou />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/sizeguide"}
              element={<SizeGuide />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/return-policy"}
              element={<ReturnPolicy />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/privacy-policy"}
              element={<PrivacyPolicy />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/shipping-policy"}
              element={<ShippingPolicy />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/account"}
              element={<MyAccount />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/existing-customer"}
              element={<ExistingCustomer />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/my-orders"}
              element={<MyOrders />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
