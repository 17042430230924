import { configureStore, combineReducers } from "@reduxjs/toolkit";
import productReducer from "./slices/product-slice";
import currencyReducer from "./slices/currency-slice";
import cartReducer from "./slices/cart-slice";
import compareReducer from "./slices/compare-slice";
import wishlistReducer from "./slices/wishlist-slice";
import categorySlice from "./slices/category-slice";
import order from "./slices/orderReducers";
import authSlice from "./slices/auth-slice";
import subCategorySlice from "./slices/subCategory-slice";
import addressSlice from "./slices/address-slice";
import { loadState } from "../helpers/persistor";
import couponSlice from "./slices/coupon-slice";

export const rootReducer = combineReducers({
  auth: authSlice,
  product: productReducer,
  currency: currencyReducer,
  cart: cartReducer,
  compare: compareReducer,
  wishlist: wishlistReducer,
  category: categorySlice,
  order: order,
  subcategory: subCategorySlice,
  address: addressSlice,
  coupon: couponSlice,
});

const presistor = loadState();

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: presistor,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
