// import React, { useState } from "react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import MenuCart from "./sub-components/MenuCart";
import { userLogout } from "../../store/actions/authActions";
import { deleteAllFromCart } from "../../store/slices/cart-slice";

const IconGroup = ({ iconWhiteClass }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
  const { compareItems } = useSelector((state) => state.compare);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems } = useSelector((state) => state.cart);

  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  // const handleChange = (e) => {
  //   setSearch(e.target.value);
  // };

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   navigate(`/shop?search=${search}`);
  //   setSearch("");
  // };

  const handleLogout = () => {
    dispatch(userLogout());
    dispatch(deleteAllFromCart());
    navigate("/login");
  };

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)}>
      <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <button
              className="button-search"
              onClick={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  navigate(`/shop?query=${search}`);
                }, 500);
              }}
            >
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div>
      <div className="same-style account-setting user-icon-mobile-setting">
        {isAuthenticated ? (
          <button
            className="account-setting-active"
            onClick={(e) => handleClick(e)}
          >
            <i className="pe-7s-user-female" />
          </button>
        ) : (
          <Link to={process.env.PUBLIC_URL + "/login"}>
            <i className="pe-7s-user-female" />
          </Link>
        )}

        <div className="account-dropdown">
          <ul>
            {isAuthenticated && (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/account"}>
                    Account Settings
                  </Link>
                </li>
                <li>
                  {
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a onClick={handleLogout}>Logout</a>
                  }
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareItems && compareItems.length ? compareItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistItems && wishlistItems.length ? wishlistItems.length : 0}
          </span>
        </Link>
      </div>
      {isAuthenticated ? (
        <>
          <div className="same-style cart-wrap d-none d-lg-block">
            <button className="icon-cart" onClick={(e) => handleClick(e)}>
              <i className="pe-7s-shopbag" />
              <span className="count-style">
                {cartItems && cartItems.length ? cartItems.length : 0}
              </span>
            </button>
            {/* menu cart */}
            <MenuCart />
          </div>
          <div className="same-style cart-wrap d-block d-lg-none">
            <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
              <i className="pe-7s-shopbag" />
              <span className="count-style">
                {cartItems && cartItems.length ? cartItems.length : 0}
              </span>
            </Link>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};

export default IconGroup;
