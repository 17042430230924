import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import clsx from "clsx";

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  // const { t } = useTranslation();
  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.subcategory);
  // const navMenu = categories.map((category) => ({
  //   id: category.id,
  //   category: t(category.name),
  //   link: `/category/${category.slug}`,
  //   subcategories: category.subcategories.map((subcategory) => ({
  //     id: subcategory.id,
  //     name: subcategory.name,
  //     link: `/category/${category.id}/subcategory/${subcategory.id}`,
  //   })),
  // }));

  const navMenu = categories.map((category) => {
    const matchingSubcategories = subcategories.filter(
      (subcategory) => subcategory?.category?._id === category?._id
    );

    const formattedSubcategories = matchingSubcategories.map((subcategory) => {
      return {
        id: subcategory?._id,
        name: subcategory.name,
        link: `/category/${category?.slug}/${subcategory?.slug}`, // Replace with the appropriate link
      };
    });

    return {
      id: category?._id,
      category: category?.name,
      link: `/category/${category?.slug}`, // Replace with the appropriate link
      subcategories:
        formattedSubcategories?.length > 0 ? formattedSubcategories : undefined,
    };
  });

  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          {navMenu.map((item) => (
            <li key={item.id}>
              <Link to={process.env.PUBLIC_URL + item.link}>
                {item.category}
                {item.subcategories && (
                  <>
                    {sidebarMenu ? (
                      <span>
                        <i className="fa fa-angle-right"></i>
                      </span>
                    ) : (
                      <i className="fa fa-angle-down" />
                    )}
                  </>
                )}
              </Link>
              {item.subcategories && (
                <ul className="submenu">
                  {item.subcategories.map((subItem) => (
                    <li key={subItem.id}>
                      <Link to={process.env.PUBLIC_URL + subItem.link}>
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;
