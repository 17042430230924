const { createSlice } = require("@reduxjs/toolkit");

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    isAuthenticated: false,
    redirect: false,
    loading: false,
    error: null,
  },
  reducers: {
    setAuth(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.redirect = true;
      state.loading = false;
    },
    UnsetAuth(state, action) {
      state.user = {};
      state.isAuthenticated = false;
      state.redirect = false;
      state.loading = false;
    },
    registerAuth(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.redirect = true;
      state.loading = false;
    },
    existingAuth(state, action) {
      state.user = {};
      state.isAuthenticated = false;
      state.redirect = false;
      state.loading = false;
    },
    redirectState(state, action) {
      state.redirect = false;
    },

    errorAuth(state, action) {
      state.error = action.payload;
    },

    resetError(state, action) {
      state.error = null;
    },
  },
});

export const {
  setAuth,
  UnsetAuth,
  registerAuth,
  redirectState,
  existingAuth,
  errorAuth,
  resetError,
} = authSlice.actions;
export default authSlice.reducer;
