const putOutOfStockItemsAtLast = (products) => {
  const outOfStockItems = products?.filter((product) => product.stock === "0");
  const inStockItems = products?.filter((product) => product.stock !== "0");
  if (inStockItems === undefined || outOfStockItems === undefined) {
    return products;
  } else {
    const data = [...inStockItems, ...outOfStockItems];
    return data;
  }
};

// get products
export const getProducts = (products, category, type, limit) => {
  return products;
};

// get product discount price
export const getDiscountPrice = (price, discount) => {
  return discount && discount > 0 ? price - price * (discount / 100) : null;
};

// get product cart quantity
export const getProductCartQuantity = (cartItems, product, color, size) => {
  let productInCart = cartItems.find(
    (single) =>
      single.id === product.id &&
      (single.selectedProductColor
        ? single.selectedProductColor === color
        : true) &&
      (single.selectedProductSize ? single.selectedProductSize === size : true)
  );
  if (cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      return cartItems.find(
        (single) =>
          single.id === product.id &&
          single.selectedProductColor === color &&
          single.selectedProductSize === size
      ).quantity;
    } else {
      return cartItems.find((single) => product.id === single.id).quantity;
    }
  } else {
    return 0;
  }
};

export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock;
  }
};

//get products based on category
export const getSortedProducts = (products, sortType, sortValue, category, subcategory) => {
  let productsData = products;
  if (products && sortType && sortValue) {
    if (sortType === "category") {
      productsData = products?.filter(
        (product) => product?.category.slug === sortValue
      );
    }

    if (sortType === "subcategory" && category) {
      //  eslint-disable-next-line
      productsData = products?.filter((product) => {
        if (product.category && product.subcategory) {
          return (
            product?.category.slug === category &&
            product?.subcategory.slug === sortValue
          );
        }
      });
    }

    if (sortType === "filterSort") {
      let sortProducts = [...products];
      if (sortValue === "default") {
        return sortProducts;
      }
      if (sortValue === "priceHighToLow") {
        return sortProducts?.sort((a, b) => {
          return b.price - a.price;
        });
      }
      if (sortValue === "priceLowToHigh") {
        return sortProducts?.sort((a, b) => {
          return a.price - b.price;
        });
      }
    }

    if (sortType === "metalType") {
      productsData = products?.filter(
        (product) =>
          product?.metalType.toLowerCase() === sortValue.toLowerCase()
      );
    }

    if (sortType === "stones") {
      productsData = products?.filter(
        (product) =>
          product?.stoneType?.toLowerCase() === sortValue?.toLowerCase()
      );
    }

    if (sortType === "stones" && category) {
      productsData = products?.filter(
        (product) =>
          product?.stoneType?.toLowerCase() === sortValue?.toLowerCase() &&
          product.category.slug === category
      );
    }

    if (sortType === "stones" && category && subcategory) {
      productsData = products?.filter(
        (product) =>
          product?.stoneType?.toLowerCase() === sortValue?.toLowerCase() &&
          product.category.slug === category &&
          product.subcategory.slug === subcategory
      );
    }

    if (sortType === "type" && sortValue) {
      if (sortValue === "best-seller") {
        productsData = products?.filter(
          (product) => product?.bestSeller === "Yes"
        );
      }
    }

    if (sortType === "AllMetalTypes" && sortValue) {
      productsData = products?.filter(
        (product) => product?.metalType && product?.metalType !== ""
      );
    }

    if (sortType === "priceFilter" && typeof sortValue === "number") {
      productsData = products.filter((item) => {
        return item.price >= sortValue;
      });
    }
  }

  const outOfStockAtLast = putOutOfStockItemsAtLast(productsData);
  return outOfStockAtLast;
};

// get individual element
const getIndividualItemArray = (array) => {
  const uniqueObjects = array.filter((item, index, self) => {
    const jsonString = JSON.stringify(item);
    return (
      self.findIndex((obj) => JSON.stringify(obj) === jsonString) === index
    );
  });

  return uniqueObjects;
};

// get individual categories
export const getIndividualCategories = (products) => {
  let productCategories = [];
  products &&
    products.map((product) => {
      return productCategories.push(product.category);
    });
  const individualProductCategories = getIndividualItemArray(productCategories);
  return individualProductCategories;
};

export const setActiveSortForStones = (buttonElement) => {
  if (buttonElement) {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left-stones button"
    );
    filterButtons.forEach((item) => {
      item.classList.remove("active");
    });
    buttonElement.classList.add("active");
  } else {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left-stones button"
    );
    filterButtons.forEach((item) => {
      item.classList.remove("active");
    });
  }
};

export const getIndividualStones = (products) => {
  let productStones = [];
  products &&
    products?.map((product) => {
      return product?.stoneType && productStones.push(product?.stoneType);
    });
  const individualProductStones = getIndividualItemArray(productStones);
  return individualProductStones;
};

// get individual tags
export const getIndividualTags = (products) => {
  let productTags = [];
  products &&
    products.map((product) => {
      return (
        product.tag &&
        product.tag.map((single) => {
          return productTags.push(single);
        })
      );
    });
  const individualProductTags = getIndividualItemArray(productTags);
  return individualProductTags;
};

// get individual colors
export const getIndividualColors = (products) => {
  let productColors = [];
  products &&
    products.map((product) => {
      return (
        product.variation &&
        product.variation.map((single) => {
          return productColors.push(single.color);
        })
      );
    });
  const individualProductColors = getIndividualItemArray(productColors);
  return individualProductColors;
};

// get individual sizes
export const getProductsIndividualSizes = (products) => {
  let productSizes = [];
  products &&
    products.map((product) => {
      return (
        product.variation &&
        product.variation.map((single) => {
          return single.size.map((single) => {
            return productSizes.push(single.name);
          });
        })
      );
    });
  const individualProductSizes = getIndividualItemArray(productSizes);
  return individualProductSizes;
};

// get product individual sizes
export const getIndividualSizes = (product) => {
  let productSizes = [];
  product.variation &&
    product.variation.map((singleVariation) => {
      return (
        singleVariation.size &&
        singleVariation.size.map((singleSize) => {
          return productSizes.push(singleSize.name);
        })
      );
    });
  const individualSizes = getIndividualItemArray(productSizes);
  return individualSizes;
};

export const setActiveSort = (buttonElement) => {
  if (buttonElement) {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left button span"
    );
    filterButtons.forEach((item) => {
      item.parentNode.classList.remove("active");
    });
    buttonElement.parentNode.classList.add("active");
  } else {
    const filterButtons = document.querySelectorAll(
      ".sidebar-widget-list-left button span"
    );
    filterButtons.forEach((item) => {
      item.parentNode.classList.remove("active");
    });
  }
};

export const setActiveLayout = (e) => {
  const gridSwitchBtn = document.querySelectorAll(".shop-tab button");
  gridSwitchBtn.forEach((item) => {
    item.classList.remove("active");
  });
  e.currentTarget.classList.add("active");
};

export const toggleShopTopFilter = (e) => {
  const shopTopFilterWrapper = document.querySelector(
    "#product-filter-wrapper"
  );
  shopTopFilterWrapper.classList.toggle("active");
  if (shopTopFilterWrapper.style.height) {
    shopTopFilterWrapper.style.height = null;
  } else {
    shopTopFilterWrapper.style.height =
      shopTopFilterWrapper.scrollHeight + "px";
  }
  e.currentTarget.classList.toggle("active");
};
