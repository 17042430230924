import { setSubCategories } from "../slices/subCategory-slice";
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_HOST

export const fetchSubCategories = () => {
    return async dispatch => {
        try {
            const response = await axios.get(`${API_URL}/subcategory`);
            dispatch(setSubCategories(response.data));
        } catch (error) {
            
        }
    };
};