const { createSlice } = require("@reduxjs/toolkit");

const productSlice = createSlice({
    name: "product",
    initialState: {
        products: [],
        loading: true,
    },
    reducers: {
        setProducts(state, action) {
            const products = action.payload;
            state.products = products;
            state.loading = false;
        },
    },
});

export const { setProducts } = productSlice.actions;
export default productSlice.reducer;